<script setup>
useHead({
    bodyAttrs: {
        class: 'bg-gray-50 text-gray-900',
    },
});
const { snackbars } = useSnackbar();
</script>

<template>
    <BaseNavMobile />
    <div class="flex min-h-screen">
        <aside>
            <slot name="aside" />
        </aside>
        <div class="grow px-5 py-7 md:px-7 md:py-14">
            <header v-if="$slots.header">
                <slot name="header" />
            </header>
            <main class="h-full">
                <slot />
            </main>
        </div>
    </div>
    <BaseSnackbar v-if="snackbars.length" :snackbars="snackbars" />
</template>
